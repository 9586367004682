import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { IPublicClientApplication, PublicClientApplication, LogLevel } from '@azure/msal-browser';
import {
  MsalInterceptor,
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptorConfiguration,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalModule
} from '@azure/msal-angular';

import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RootComponent } from './root/root.component';
import { environment } from 'src/environments/environment';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { UnauthorisedComponent } from './unauthorised/unauthorised.component';
import { ChatterModule } from './chatter/chatter.module';
import { RlasApiModule } from './rlas-api/rlas-api.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

/**
 * Module for the App.
 */
@NgModule({
  declarations: [
    RootComponent,
    UnauthorisedComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    HttpClientModule,
    MsalModule,
    ChatterModule,
    AppRoutingModule,
    NgSelectModule,
    FormsModule,
    RlasApiModule,
    NgbModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true },
    {
      provide: MSAL_INSTANCE,
      useFactory: (): IPublicClientApplication => new PublicClientApplication({
        ...environment.msalConfig,
        system: {
          ...environment.msalConfig.system,
          loggerOptions: {
            ...environment.msalConfig.system.loggerOptions,
            loggerCallback: (logLevel: LogLevel, message: string): void => {
              switch (logLevel) {
                case LogLevel.Error:
                  console.error(message);
                  break;
                case LogLevel.Warning:
                  console.warn(message);
                  break;
                default:
                  console.log(message);
                  break;
              }
            }
          }
        }
      })
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: (): MsalGuardConfiguration => environment.msalGuardConfig
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: (): MsalInterceptorConfiguration => environment.msalInterceptorConfig
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService
  ],
  bootstrap: [RootComponent]
})
export class AppModule { }
