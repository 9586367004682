import { inject, NgModule } from '@angular/core';
import { CanActivateFn, Router, RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { map } from 'rxjs';
import { LoggedOutComponent } from './logged-out/logged-out.component';
import { SecurityGroup, UserService } from './rlas-api';
import { UnauthorisedComponent } from './unauthorised/unauthorised.component';

/**
 * A factory to produce a {@link CanActivateFn} to check for Group memebership.
 *
 * @param group The Group to check for.
 * @param systemArea The area of the system that access is being granted or refused for.
 * @returns The {@link CanActivateFn}.
 */
const requireGroup = (group: SecurityGroup, systemArea: string): CanActivateFn => () => {
  const router = inject(Router);

  return inject(UserService).me$.pipe(
    map(
      u => u.groups.includes(group) ||
        router.createUrlTree(['/unauthorised', systemArea])
    )
  );
};

const routes: Routes = [
  {
    path: '',
    redirectTo: 'auth',
    pathMatch: 'full'
  },
  {
    canActivate: [MsalGuard],
    path: 'auth',
    children: [
      {
        path: '',
        redirectTo: 'la',
        pathMatch: 'full'
      },
      {
        path: 'la',
        canActivate: [requireGroup(SecurityGroup.rlasConfigAccess, 'RLAS Management')],
        loadChildren: () => import('./authority/authority.module').then(m => m.AuthorityModule)
      },
      {
        path: 'mapping',
        canActivate: [requireGroup(SecurityGroup.rlasMappingAccess, 'Sherlock')],
        loadChildren: () => import('./mapping/mapping.module').then(m => m.MappingModule)
      }
    ]
  },
  {
    path: 'logged-out',
    component: LoggedOutComponent
  },
  {
    path: 'unauthorised/:page',
    component: UnauthorisedComponent
  }
];


/**
 * Module for App Routing.
 */
@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
