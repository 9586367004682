import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

/**
 * The application root component.
 */
@Component({
  selector: 'rm-root',
  templateUrl: './root.component.html',
  styleUrls: ['./root.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RootComponent {
  /**
   * A pipeline which emits a value indicating whether the loading message should be shown.
   */
  protected readonly showPlaceholder$ = new BehaviorSubject(true);
}
