<router-outlet (activate)="showPlaceholder$.next(false)"
               (deactivate)="showPlaceholder$.next(true)">
</router-outlet>
<div *ngIf="showPlaceholder$ | async"
     class="loading">
  <h4>
    Loading RLAS Management
    <span class="dots">
      <span>.</span>
      <span>.</span>
      <span>.</span>
    </span>
  </h4>
</div>
