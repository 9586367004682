import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

/**
 * A component to handle unauthorised access.
 */
@Component({
  selector: 'rm-unauthorised',
  templateUrl: './unauthorised.component.html',
  styleUrls: ['./unauthorised.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UnauthorisedComponent {
  /**
   * Creates an instance of the Unauthorised component.
   *
   * @param route The activated route.
   */
  constructor(route: ActivatedRoute) {
    route.params.subscribe({
      next: params => this.pageName = params.page
    });
  }

  /**
   * The name of the page that could not be accessed.
   */
  public pageName: string;
}
