import { Component } from '@angular/core';

/**
 * A component to handle logged out of system.
 */
@Component({
  selector: 'rm-logged-out',
  templateUrl: './logged-out.component.html',
  styleUrls: ['./logged-out.component.scss']
})

/**
 * The component displayed when the user logs out of the application.
 */
export class LoggedOutComponent {
}
